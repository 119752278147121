.container {
  display: flex;
  flex-direction: column;
  max-width: 56.2rem;
  margin-bottom: 9rem;
}

.container > *:not(:last-child),
.formContainer > *:not(:last-child),
.formChildContainer > *:not(:last-child) {
  margin-bottom: 1.8rem;
}

.schoolChildContainer > *:not(:last-child) {
  margin-bottom: 0.1rem;
}

.titleHeading {
  font-size: 3.4rem;
  color: var(--text-regular);
  font-weight: 600;
}

.barLine {
  background-color: var(--gray-inactive);
  height: 8px;
  border-radius: 100px;
  display: flex;
}

.fillLine {
  background: var(--primary-full);
  width: 50%;
  height: 8px;
  border: 1px solid var(--primary-shade1);
  border-radius: 100px;
}

.teacherFillLine {
  width: 33.3%;
}

.card {
  background-color: var(--background-card);
  border-radius: var(--border-radius);
  padding: 1.6rem;
}

.formTitle {
  font-weight: 500;
  font-size: 2rem;
  color: var(--text-regular);
}

.formSubtitle {
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--text-regular);
}

.gridContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
}

.link {
  text-decoration: none;
  color: var(--primary-full);
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  /* margin-right: 1.2rem; */
  width: 3.4rem;
  height: 3.4rem;
  text-align: end;
}
