.profileContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  /* column-gap: 16rem; */
  padding: 1.6rem;
}

.profileContainer > * {
  /* flex: 50%; */
  /* display: flex;
    justify-content: flex-start;
    align-items: center; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2.4rem;
  margin-top: 2rem;
}

/* tab container */
.tabContainer {
  display: inline-flex;
  column-gap: 2rem;
  margin-left: 2rem;
}

.tabItem {
  cursor: pointer;
}

.tabItem p {
  font-weight: 300;
  font-size: 1.7rem;
  line-height: 24px;
  color: var(--text-secondary);
}

.tabItem span {
  display: none;
}

/* active tab */
.tabItem.active p {
  font-weight: 500;
  color: var(--primary-full);
}

.tabItem.active span {
  display: block;
  width: 2rem;
  height: 0.4rem;
  background-color: var(--primary-full);
  border-radius: 4px;
}
