.listingContainer {
  /* width: 100%; */
  min-width: 80rem;
  margin-top: 1.6rem;
}

.headerContainer {
  width: 100%;
  margin-bottom: 0.2rem;
  border-radius: 8px;
}

thead.headerContainer tr th {
  font-weight: 500;
  text-align: left;
}

.itemContainer {
  background-color: var(--background-card);
  padding: 1.2rem 2rem 0.8rem;
  display: flex;
  justify-content: space-between;
  /* display: grid;
  grid-template-columns: repeat(4, 1fr); */
  /* width: 30rem; */
}

.itemContainer > * {
  flex: 25%;
}

thead.headerContainer tr {
  width: 100%;
  padding: 2rem 1.6rem;
}

/* last columns */
/* header */
tr.itemContainer th:not(:first-child) {
  text-align: center;
}
tr.itemContainer th:last-child {
  text-align: end;
}

/* cell */
tr.itemContainer td:not(:first-child) {
  text-align: center;
}
tr.itemContainer td:last-child {
  text-align: end;
}

.itemContainer:not(:last-child) {
  box-shadow: inset 0px -0.5px 0px rgba(226, 219, 217, 0.6);
}

/* text */
.headerText {
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--text-regular);
}

.rowColumnText {
  font-weight: 300;
  font-size: 1.5rem;
  color: var(--text-secondary);
}

/* border radius  */
.headerCellContainer {
  border-radius: 8px 8px 0px 0px;
}

.rowContainer:last-child {
  border-radius: 0px 0px 8px 8px;
}
