.floatingLabel {
  position: absolute;
  pointer-events: none;
  left: 2rem;
  top: 1.8rem;
  transition: 0.2s ease all;

  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0.4px;
  color: var(--text-tertiary);
  top: 8px;
  bottom: 10px;
  left: 2rem;
  font-weight: 400;
  font-size: 1.4rem;
  opacity: 1;
  color: var(--text-tertiary);
}
