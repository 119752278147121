.mainContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1.6rem;
  row-gap: 2rem;

  grid-template-areas:
    "first first second second second"
    "third third third third third";
}

.gridfirstchild {
  grid-area: first;
}

.gridSecondchild {
  grid-area: second;
}

.gridThirdchild {
  grid-area: third;
  /* height: 41.2rem; */
}
