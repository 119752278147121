.timecontainer {
  position: relative;
  width: 100%;
}

.inputText {
  width: 100%;
  height: 5.1rem !important;
}

.label {
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0.4px;
  color: var(--text-tertiary);
}

.iconContainer {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.error.inputText {
  border-color: var(--alert-error) !important;
}

.warning.inputText {
  border-color: var(--alert-warning) !important;
}

.success.inputText {
  border-color: var(--alert-success) !important;
}
