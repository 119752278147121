.dots-5 {
  width: 1.2rem;
  height: 1.2rem;
  margin: auto;
  background: var(--text-secondary);
  border-radius: 50%;
  box-shadow: 20px 0 #00000022, -20px 0 var(--text-secondary);
  animation: d5 1s infinite linear alternate;
}
@keyframes d5 {
  0% {
    box-shadow: 20px 0 var(--text-secondary), -20px 0 #00000022;
    background: var(--text-secondary);
  }
  33% {
    box-shadow: 20px 0 var(--text-secondary), -20px 0 #00000022;
    background: #00000022;
  }
  66% {
    box-shadow: 20px 0 var(--text-secondary), -20px 0 var(--text-secondary);
    background: #00000022;
  }
}
