.cardContainer {
  width: 100%;
  background-color: var(--background-card);
  border-radius: var(--border-radius-large);
  padding: 2.4rem 0.8rem 0px;
}

.cardWrapperContainer {
  height: auto;
  width: 100%;
  padding: 1.6rem 1.2rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
  /* background-color: red; */
}

/* applicant list styles */

.rowContainer {
  display: flex;
  justify-content: space-between;
  /* grid-template-columns: repeat(5, 1fr); */
  /* align-items: center; */
  cursor: auto;
}

.rowContainer > * {
  flex: 30%;
}

.rowContainer > *:last-child {
  flex: 20%;
}

.cursor {
  cursor: pointer;
}

.fullName {
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--text-regular);
}

.contantNumber {
  font-weight: 300;
  font-size: 1.5rem;
  color: var(--text-secondary);
}

.filterAction {
  font-size: 4rem;
  color: var(--primary-full);
  line-height: 0px;
  cursor: pointer;
}

/* hightlight row */
.highlightRow {
  background-color: var(--border-color);
}

.highlightRow > *,
.highlightRow .fullName,
.highlightRow .contantNumber {
  color: var(--primary-full);
}

tr.rowContainer th:not(:first-child) {
  text-align: center;
}

tr.rowContainer td:not(:first-child) {
  text-align: center;
}

/* last columns */
/* header */
tr.rowContainer th:last-child {
  text-align: end;
}

/* cell */
tr.rowContainer td:last-child {
  text-align: end;
}
