.main {
  width: 100%;
}

.container {
  position: relative;
  width: 100%;
  height: 6.1rem;
  /* margin:1rem; */
}

.inputText {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border: 2px solid var(--background);
  border-radius: var(--border-radius); */
  border: 1px solid var(--border-color);
  color: var(--text-regular);
  border-radius: 0.8rem;
  font-family: inherit;
  outline: none;
  padding-left: 1.8rem;
  padding-top: 2rem;
  background: none;
  transition: border 100ms ease-in-out;
  font-size: 1.8rem;
}

.inputText:disabled {
  opacity: 0.6;
}

.error .inputText {
  border-color: var(--alert-error);
}

.warning .inputText {
  border-color: var(--alert-warning);
}

.success .inputText {
  border-color: var(--alert-success);
}

/* .inputText:focus,
.inputText:hover {
  border-width: 3px;
  outline: none;
} */

.floatingLabel {
  position: absolute;
  pointer-events: none;
  left: 2rem;
  top: 1.8rem;
  transition: 0.2s ease all;

  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0.4px;
  color: var(--text-tertiary);
}

.inputText:focus ~ .floatingLabel,
.inputText:not(:focus):valid ~ .floatingLabel,
.inputText:disabled ~ .floatingLabel {
  top: 6px;
  bottom: 10px;
  left: 2rem;
  font-weight: 400;
  font-size: 1.4rem;
  opacity: 1;
  color: var(--text-tertiary);
}

.inputText:not(:focus):valid {
  border-width: 1px;
}

.iconContainer {
  position: absolute;
  right: 1rem;
  top: 1.8rem;
  cursor: pointer;
}

.icon {
  height: 20px;
  width: 20px;
  padding: 2px;
  border-radius: 50%;
  /* opacity: 0; */
  opacity: 1;
}

.icon.error {
  background-color: var(--third-color);
  opacity: 1;
}

.icon.success {
  background-color: var(--success-color);
  opacity: 1;
}

.icon.warning {
  background-color: var(--lite-orange);
  opacity: 1;
}

.errorMessage {
  color: var(--alert-error);
  margin-top: 5px;
  font-size: 1.5rem;
}
