.listingContainer {
  width: 100%;
  margin-top: 0.8rem;
}

.headerContainer {
  width: 100%;
  margin-bottom: 0.2rem;
  border-radius: 8px;
}

thead.headerContainer tr th {
  font-weight: 500;
  text-align: left;
}

.itemContainer {
  background-color: var(--background-card);
  padding: 1.2rem 2rem 0.8rem;
  /* display: grid;
  grid-template-columns: repeat(6, 1fr); */
  display: flex;
  justify-content: space-between;
}

.itemContainer > * {
  flex: 30%;
}

.itemContainer > *:last-child {
  flex: 10%;
}

.dateTimeColumn,
.schoolLocation {
  flex: 40%;
  padding: 0px 1rem 0px 0px;
}

.dateTimeColumn:not(:first-child),
.schoolLocation:not(:first-child) {
  padding-left: 1rem;
}

thead.headerContainer tr {
  width: 100%;
  padding: 2rem 1.6rem;
}

/* last columns */
/* header */
tr.itemContainer th:last-child {
  text-align: end;
}

/* cell */
tr.itemContainer td:last-child {
  text-align: end;
}

.itemContainer:not(:last-child) {
  box-shadow: inset 0px -0.5px 0px rgba(226, 219, 217, 0.6);
}

/* text */
.headerText {
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--text-regular);
}

.rowColumnText {
  font-weight: 300;
  font-size: 1.5rem;
  color: var(--text-secondary);
}

.row .link {
  cursor: pointer;
}

/* border */
.headerCellContainer {
  border-radius: 8px 8px 0px 0px;
}

.rowContainer {
  cursor: pointer;
}

.rowContainer:last-child {
  border-radius: 0px 0px 8px 8px;
}

/* filter header styles */
.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* padding: 8px 16px; */
  gap: 2.4rem;
}

/* status styles */

.open {
  color: #4a7d9a;
}

.booked {
  color: #6c6a94;
}

.cancelled {
  color: #a04d47;
}

.completed {
  color: #528c28;
}
