.button {
  border-radius: 0.8rem;
  border: none;
  color: var(--background-card);
  font-family: inherit;
  font-weight: 600;
  font-size: 1.6rem;
  height: 5.7rem;
  outline: none;
  text-transform: capitalize;
  width: 100%;
  cursor: pointer;
}

.withBackground {
  background: var(--primary-full);
}

.noBackground {
  background-color: transparent;
  color: var(--primary-full);
  border: 2px solid var(--primary-full);
}

.button.withBackground:disabled {
  background: var(--primary-shade2);
  color: var(--primary-shade1);
}

.grayBackground {
  background-color: var(--light-gray-color);
}

.gredientBackground {
  background: var(--button-gradient);
}
