.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.textContainer {
  flex: 60%;
  align-items: flex-end;
}

.buttonContainer {
  flex: 40%;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
}
