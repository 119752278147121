/* tab container */
.tabContainer {
  display: inline-flex;
  column-gap: 4rem;
  padding: 2.4rem 1.2rem 1.2rem;
}

.tabItem {
  cursor: pointer;
  text-decoration: none;
}

.tabItem p {
  font-weight: 300;
  font-size: 1.7rem;
  line-height: 24px;
  color: var(--text-secondary);
}

.tabItem span {
  display: none;
}

/* active tab */
.tabItem.active p {
  font-weight: 500;
  color: var(--primary-full);
}

.tabItem.active span {
  display: block;
  width: 2rem;
  height: 0.4rem;
  background-color: var(--primary-full);
  border-radius: 4px;
}
