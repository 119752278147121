.layoutContainer {
  display: flex;
  justify-content: center;
  padding: 7rem 10rem;
  overflow-y: scroll;
}

.contentContainer {
  display: flex;
}

.layoutContainer > * {
  flex: 1;
}

.imageStyle {
  max-height: 60rem;
  max-width: 60rem;
  margin-top: 10rem;
  margin-right: 7rem;
}
