.mainContainer {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 1.6rem;
}

.jobDetailsContainer {
  flex: 60%;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.schoolDetailsContainer {
  flex: 40%;
}

.contentContainer {
  margin-top: 0.8rem;
  padding: 0.8rem 1.6rem 0.4rem;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}

.contentItem {
  padding: 0.8rem 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.contentItem:not(:last-child) {
  box-shadow: inset 0px -0.5px 0px rgba(226, 219, 217, 0.6);
}

.labelText {
  font-weight: 400;
  font-size: 1.3rem;
  color: var(--text-secondary);
}

.valueText,
.schoolLocation {
  font-weight: 300;
  font-size: 1.5rem;
  color: var(--text-regular);
}

.schoolImage {
  height: 14rem;
  width: 14rem;
  border-radius: var(--border-radius);
}

.schoolLocation {
  color: var(--text-secondary);
  margin-top: -1rem;
}

/* for apply job design page */
.schoolAddress {
  color: var(--text-tertiary);
}
