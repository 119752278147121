.container {
  /* position: fixed; */
  /* top: 0px; */

  position: absolute;
  top: 0px;
  left: 19.2rem;
  width: calc(100% - 19.2rem);
  height: 6.8rem;

  z-index: 100;
  box-shadow: inset 0px -0.5px 0px #e2dbd999;
  background-color: var(--background-card);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.profileContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.4rem;
  margin-right: 2rem;
}

.title {
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--text-regular);
}

.logoImage {
  width: 3.6rem;
  height: 3.6rem;
}
