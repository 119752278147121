.container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.container > div:first-child {
  height: 100%;
  /* flex: 15%; */
}

.container > div:last-child {
  /* top: 20% !important; */
  /* flex: 85%; */
  padding: 8rem 3.2rem 3.2rem;
  /* padding: 0px 30px; */
  width: 100%;
  overflow-y: scroll;
}

/* .mainContainer{
    flex: 77%;
    width: 100%;
  } */

/* sticky main content */
/* .mainContainer {
    position: fixed;
    top: 6rem;
    left: 16rem;
    width: 100%;
    height: 90vh;
    overflow-y: scroll;
  } */
/* sticky main content end */
