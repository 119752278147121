/* portal */
.modaloverlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  background-color: rgb(0, 0, 0);
  opacity: 0.7;
  z-index: 500;
}

/* global class used every where */
.confirmModal,
.modalContainer {
  z-index: 99999;
  position: absolute;
  z-index: 1000;
  top: 40%;
  height: 30rem;
  width: 70rem;
  margin-top: 10rem;
  left: calc(100% - 50%);
  transform: translate(-50%, 30%);
  display: flex;
  flex-direction: column;
}

/* select pic modal */

.selectPicModal {
  /* width: fit-content;
    height: fit-content; */
  width: 50rem;
  /* max-width: 500px; */
  max-height: 50rem;
}

.selectImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.closeIconContainer {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--color-white);
  color: var(--color-white);
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.closeIcon {
  cursor: pointer;
}

/*snacks ingredient modal  & confirmModal */
.tabItem {
  font-size: 18px;
  font-weight: 500;
  /* cursor: pointer; */
}

.heading {
  font-weight: 700;
  font-size: 22px;
}
