.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.textContainer {
  flex: 85%;
}

.buttonContainer {
  flex: 15%;
  display: flex;
  justify-content: flex-end;
}

/* labelWithInputField component style */

.containerPadding {
  padding: 0.5rem 0px;
}

.label {
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 24px;
}

.labelContainer {
  flex: 12%;
}

.formFieldContainer {
  flex: 88%;
  display: flex;
  justify-content: flex-end;
}
