.container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.radioFlexContainer,
.flexContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radioFlexContainer > *:first-child {
  flex: 54%;
}

.radioFlexContainer > *:last-child {
  flex: 46%;
}

.gridContainerWithThreeCol {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
}

.flexContainer > *:first-child,
.flexContainer > *:last-child {
  flex: 47%;
}

.flexContainer > *:nth-child(2) {
  flex: 4%;
  text-align: center;
  font-weight: 300;
  font-size: 1.5rem;
  color: var(--text-secondary);
}
