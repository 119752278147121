.redirectContainer {
  display: flex;
  justify-content: flex-start;
  column-gap: 2rem;
}

.tab,
.active {
  font-weight: 300;
  padding: 0px;
  font-size: 1.5rem;
  color: var(--text-secondary);
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.active {
  color: var(--primary-full);
  font-weight: 400;
}

.arrow {
  margin-right: 2rem;
  font-size: 3rem;
  color: var(--text-secondary);
  margin-top: -0.7rem;
}
