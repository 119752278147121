.sideNav {
  /* sticky sidebar */
  /* position: fixed;
    top: 6rem; */
  height: 100%;
  width: 19.2rem;
  /* padding: 30px 0px; */
  /* color: var(--color-white); */
  background-color: var(--background-card);
  /* border-top-right-radius: 12px; */
}

.logoHeader {
  width: 19.2rem;
  height: 6.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 2.5rem 1.5rem 3.2rem;
}

.logoImage {
  height: 3.6rem;
}

/* .menuWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  } */

.logOutWrapper {
  border-top: 1px solid var(--dark-border-color);
  margin-bottom: 20px;
}

.menuList,
.logOutWrapper {
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
}

.menuList .menuItem,
.logOutWrapper .menuItem {
  color: var(--text-secondary);
  text-decoration: none;

  font-weight: 300;
  font-size: 1.7rem;
  font-family: var(--primary-font);

  padding: 2.4rem 1.6rem 2.4rem 3.2rem;

  display: flex;
  align-items: center;
}

.menuItem {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  margin-right: 1.2rem;
  width: 2.4rem;
  height: 2.4rem;
}

.active {
  /* width: 90%; */
  font-weight: 500;
  border-radius: 0px 12px 12px 0px;
  background-color: var(--primary-full);
  color: var(--background-card) !important;
}

@media screen and (min-width: 1400px) {
  .sideNav {
    width: 20rem;
  }
}
