.listingContainer {
  width: 100%;
  margin-top: 0.8rem;
}

.headerContainer {
  width: 100%;
  margin-bottom: 0.2rem;
  border-radius: 8px;
}

thead.headerContainer tr th {
  font-weight: 500;
  text-align: left;
}

.itemContainer {
  background-color: var(--background-card);
  padding: 1.2rem 2rem 0.8rem;
  /* display: grid;
  grid-template-columns: repeat(5, 1fr); */
  display: flex;
  justify-content: space-between;
}

.itemContainer > * {
  flex: 20%;
}

.itemContainer > *:last-child {
  flex: 20%;
}

.expertiesColumn {
  flex: 40%;
}

/* .verifyDate {
  flex: 10%;
} */

thead.headerContainer tr {
  width: 100%;
  padding: 2rem 1.6rem;
}

tr.itemContainer th:not(:first-child) {
  text-align: center;
}

tr.itemContainer td:not(:first-child) {
  text-align: center;
}

/* last columns */
/* header */
tr.itemContainer th:last-child {
  text-align: end;
}

/* cell */
tr.itemContainer td:last-child {
  text-align: end;
}

.itemContainer:not(:last-child) {
  box-shadow: inset 0px -0.5px 0px rgba(226, 219, 217, 0.6);
}

/* text */
.headerText {
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--text-regular);
}

.rowColumnText {
  font-weight: 300;
  font-size: 1.5rem;
  color: var(--text-secondary);
}

/* border */
.headerCellContainer {
  border-radius: 8px 8px 0px 0px;
}

.rowContainer:last-child {
  border-radius: 0px 0px 8px 8px;
}

/* filter header styles */
.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* padding: 8px 16px; */
  gap: 2.4rem;
}

.fullName {
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--text-regular);
}

.contantNumber {
  font-weight: 300;
  font-size: 1.5rem;
  color: var(--text-secondary);
}

.filterAction {
  font-size: 4rem;
  color: var(--primary-full);
  line-height: 0px;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}
