.homeCard {
  padding: 2.4rem 1.6rem 2.4rem;
  border-radius: var(--border-radius-large);
  background-color: var(--background-card);
}

/* listing component styles */
.listingContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.listingContainer > * {
  flex: 33.3%;
}

/* listing item styles */
.listingItem {
  background-color: var(--background-card);
  border: 1px solid #eee9e8;
  border-radius: var(--border-radius);
  padding: 1.6rem;
  margin: 1.6rem 1.6rem 0px;
  cursor: pointer;
}

.listingItem:nth-child(1n) {
  margin-left: 0px;
}

.listingItem:nth-child(3n) {
  margin-right: 0px;
}

.rowContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
}

.positionTitle {
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--text-regular);
}

.classYearText {
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--text-secondary);
}

.address {
  font-weight: 300;
  font-size: 1.2rem;
  color: var(--text-tertiary);
}

.unorderList {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}

.unorderList > *:first-child {
  list-style: none;
}

.startDate {
  font-weight: 500;
  font-size: 1rem;
  color: var(--text-secondary);
  text-transform: uppercase;
}

.jobType {
  font-weight: 400;
  font-size: 1.3rem;
  color: var(--text-secondary);
}

.recurrence {
  font-weight: 400;
  font-size: 1.3rem;
  color: var(--text-secondary);
}

.emergency {
  color: #b7414b;
}

.longItem {
  color: #528c28;
}
