@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

:root {
  /* primary colors */
  --primary-full: #de5f35;
  --primary-shade1: #c7b2ac;
  --primary-shade2: #eee0d8;

  /* secondary colors */
  --secondary-full: #5a489b;

  /* alert colors */
  --alert-warning: #ffd20c;
  --alert-success: #528c28;
  --alert-error: #b7414b;

  /* gray colors */
  --gray-inactive: #aeb2b9;
  --gray-border: #e1ddda;
  --border-color: #eee9e8;

  /* text colors */
  --text-regular: #181c29;
  --text-secondary: #565b64;
  --text-tertiary: #99a0ac;

  /* background */
  --background-card: #ffffff;
  --background-page: #f8f5f1;

  --button-gradient: linear-gradient(
    to right,
    rgb(255, 226, 193) 0%,
    rgb(255, 186, 181) 100%
  );
  --primary-font: "Outfit", sans-serif;
  --weight1: 700;
  --weight2: 600;
  --weight3: 500;
  --weight4: 400;
  --border-radius: 8px;
  --border-radius-large: 12px;
}

html {
  font-size: 62.5%;
  height: 100%;
  width: 100%;
}

html {
  /* font-size: 10px;  */
  /* 62.5% of 16px = 10px */
  line-height: 1.5;
}

body {
  z-index: 1;
  position: relative;
  overflow: scroll;
  margin: 0;
  padding: 0;
  /* height: 100%;
  width: 100%; */
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100vh;
  max-width: 100vw;
  font-family: var(--primary-font);
  background-color: var(--background-page);
  font-size: 16px;
}

/* body,
#root {
  height: 100vh;
} */

/* #root .app {
  height: 100vh;
} */

.note {
  font-size: 13px;
  color: var(--text-color);
}

/* Capitalize */
h1:first-letter,
h2:first-letter,
h3:first-letter,
h4:first-letter,
h5:first-letter,
h6:first-letter,
p:first-letter,
.first-letter-uppercase:first-letter {
  text-transform: uppercase !important;
}

a {
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--primary-color);
}

.w-700 {
  font-weight: var(--weight1);
}

.w-600 {
  font-weight: var(--weight2);
}

.w-500 {
  font-weight: var(--weight3);
}

.w-400 {
  font-weight: var(--weight4);
}

.error {
  color: var(--alert-error);
}

/* error message */
.errorMessage {
  color: var(--alert-error);
  margin-top: 5px;
  font-size: 1.5rem;
}

/* network detector css */
.internet-error {
  width: 100%;
  position: fixed;
  height: 60px;
  background: var(--button-gradient);
  margin-top: 0;
  font-size: 20px;
  z-index: 1000;
}

.internet-error p {
  font-size: 22px;
  line-height: 60px;
  color: var(--color-text-black);
  margin: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.center {
  text-align: center;
}

/* scroll bar styles */
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* @media screen and (max-width: 1300px) {
  html {
    font-size: 48%;
  }
} */

@media screen and (max-width: 1300px) {
  html {
    font-size: 44.5%;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 40.5%;
  }
}

/* time picker override styles */
.rc-time-picker-input {
  outline: none;
  width: 100%;
  height: 5rem !important;
  color: var(--text-regular) !important;
  font-size: 1.8rem !important;
  border: 1px solid var(--border-color) !important;
}

.rc-time-picker-input::placeholder,
.rc-time-picker-input:-ms-input-placeholder,
.rc-time-picker-input::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 400 !important;
  font-size: 1.6rem !important;
  letter-spacing: 0.4px !important;
  color: var(--text-tertiary) !important;
}
