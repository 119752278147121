.button {
  border: none;
  border-radius: 0.8rem;
  border-radius: var(--border-radius-large);
  font-family: inherit;
  font-weight: 600;
  font-size: 1.6rem;
  color: var(--background-card);
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  height: 4.8rem;
  width: 100%;
  padding: 0px 3rem;
  text-transform: capitalize;
  cursor: pointer;
}

.icon {
  flex: 20%;
  width: 3.2rem;
  text-align: right;
}

.withBackground {
  background: var(--primary-full);
}

.noBackground {
  background-color: transparent;
  color: var(--primary-full);
  border: 2px solid var(--primary-full);
}

.buttonText {
  flex: 80%;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
  text-align: end;
}
