.label {
  font-weight: 300;
  font-size: 1.5rem;
  color: var(--text-secondary);
}

.group {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.radioInput {
  margin-right: 1.2rem;
  padding: 0.5rem;
  accent-color: var(--primary-full);
  width: 2rem;
  height: 2rem;
}

.radioInput[type="radio"]:checked::before {
  content: "";
  display: block;
  position: relative;
  border-radius: 50%;
}
