.container {
  position: relative;
}

.selectBtn {
  width: 100%;
  min-height: 6.1rem;
  border: 1px solid var(--border-color);
  border-radius: 0.8rem;
  padding-left: 1.8rem;
  padding-right: 1.1rem;

  /* padding-top: 2rem; */

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.error.selectBtn {
  border-color: var(--alert-error);
}

.warning.selectBtn {
  border-color: var(--alert-warning);
}

.success.selectBtn {
  border-color: var(--alert-success);
}

.btnText {
  padding: 0px;
  margin: 0px;
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0.4px;
  color: var(--text-tertiary);
}

.selectedValue {
  color: var(--text-regular);
  font-size: 1.8rem;
  font-weight: 400;
  margin-top: -0.6rem;
}

.btnTextSmall {
  font-weight: 400;
  font-size: 1.4rem;
}

.checkboxContainer {
  min-width: 60%;
  max-width: 70%;
  z-index: 100;
  position: absolute;
  margin-top: 1rem;
  background-color: #ffffff;
  border-radius: 1.2rem;
  padding: 1.2rem 1.8rem;
  box-shadow: 0px 0px 100px 5px rgba(0, 0, 0, 0.1);
  display: none;
}

.open {
  display: block;
}

.label {
  font-size: 1.5rem;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 300;
  color: var(--text-secondary);
  cursor: pointer;
}

.checkBoxItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 0.4rem;
}

.checkBoxItem:not(:last-child) {
  border-bottom: 1px solid #f8f5f1;
}

.checkbox {
  -webkit-appearance: none;
  width: 2rem;
  height: 2rem;
  /* border: 1px solid #d9d9d9; */
  border: 2px solid rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  vertical-align: sub;
  outline: none;
  position: relative;
  cursor: pointer;
}

.checkbox:hover,
.checkbox:focus,
.checkbox:checked {
  border: 2px solid rgba(0, 0, 0, 0.6);
}

.checkbox:checked {
  background-color: var(--primary-full);
  border: none;
}

.checkbox:checked::after {
  content: "✓";
  font-size: 1.5rem;
  font-weight: bold;
  width: 2rem;
  height: 2rem;
  color: var(--background-card);
  position: absolute;
  left: 0;
  top: 0px;
  transform: translate(20%, 5%);
  /* background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==); */
  /* background-size: 40px;
  background-repeat: no-repeat; */
  background-position: center;
}
