.InputfieldContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.inputText {
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  /* border: 2px solid var(--background);
    border-radius: var(--border-radius); */
  border: 1px solid var(--border-color);
  color: var(--text-regular);
  border-radius: 0.8rem;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  padding: 0px 1.2rem;

  background: none;
  transition: border 100ms ease-in-out;
  font-size: 1.8rem;
}

.inputText::placeholder {
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0.4px;
  color: var(--text-tertiary);
}

/* input date */
input.inputText[type="date"]::-webkit-calendar-picker-indicator {
  background: url(../../assets/icon/calendar.png) no-repeat;
  width: 20px;
  height: 20px;
  background-position: center;
  background-size: 3rem;
  border-width: thin;
}

/* select dropdown */
.selectBtn {
  outline: none;
  background: none;

  width: 100%;
  height: 5rem;

  border-radius: 0.8rem;
  border: 1px solid var(--border-color);

  padding-top: 0.5rem;
  padding-left: 1.8rem;
  padding-right: 1.1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  font-size: 1.8rem;
  color: var(--text-regular);
  transition: border 100ms ease-in-out;
}

.label {
  font-weight: 300;
  font-size: 1.5rem;
  letter-spacing: 0.4px;
  color: var(--text-secondary);
}

.hideLabel {
  display: none;
}

.icon {
  height: 20px;
  width: 20px;
  padding: 2px;
  border-radius: 50%;
  opacity: 1;
}

.checkboxContainer {
  margin-top: 5rem;
}

/* filter selection */
.filterMainContainer {
  width: auto;
}

.filterSelectedButton {
  border-radius: 2rem;
  width: 20rem;
}

.filterSelectedButton .label {
  color: var(--text-regular);
}

.filterCheckbox {
  min-width: 25rem !important;
}

/* error and success styles */
.error.inputText,
.error.selectBtn {
  border-color: var(--alert-error);
}

.warning.inputText,
.warning.selectBtn {
  border-color: var(--alert-warning);
}

.success.inputText,
.success.selectBtn {
  border-color: var(--alert-success);
}

/* textarea tag style */
textarea.inputText {
  color: var(--text-regular);
  font-size: 1.8rem;
  height: 10rem;
  padding: 2rem;
}

textarea.inputText::placeholder {
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--text-tertiary);
  letter-spacing: 0.4px;
  position: absolute;
  top: 4rem;
}
